<template>
  <div class="map-wrapper" :class="{ 'map-wrapper--collapsed': isMapCollapsed }" ref="mapWrapper">
    <div id="map" class="map"></div>
    <simple-btn
      color="red"
      @btnClicked="toggleMapHeight"
      v-if="isShowToggle"
      :class="{ active: !isMapCollapsed }"
    >
      <template #icon>
        <img src="@/assets/img/button/chevron-down.svg" alt="↓" />
      </template>
    </simple-btn>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { clone } from 'ramda'
import {
  zonePointGeometry,
  zonePointPreset,
  zoomOptions,
  searchControlOptions,
  commonMapOptions,
  getPlaceMarkCaption
} from '@/utils'
// import { polygons } from '@/archive/wind'
import SimpleBtn from '@/components/dump/Page-elements/SimpleBtn'

const mapOptions = {
  center: commonMapOptions.center,
  zoom: commonMapOptions.zoom,
  controls: ['searchControl']
}

export default {
  props: {
    tableHeaderData: {
      type: Object,
      default: () => ({}),
      required: true
    },
    isMapCollapsed: {
      type: Boolean,
      required: true
    },
    isShowToggle: {
      type: Boolean,
      required: true
    }
  },
  name: 'YaMapPolygons',
  data: () => ({
    myMap: null,
    searchControl: null,
    zonePoint: null,
    mapZones: {},
    isSkeletonShow: true
  }),
  components: { SimpleBtn },
  watch: {
    isMapCollapsed() {
      setTimeout(() => {
        this.myMap.container.fitToViewport()
      })
    }
  },
  methods: {
    toggleMapHeight() {
      this.$emit('toggleMapHeight', !this.isMapCollapsed)
    },
    initMap() {
      this.myMap = new ymaps.Map('map', mapOptions, zoomOptions)
      window.zonePoint = new ymaps.GeoObject(zonePointGeometry, zonePointPreset)
      this.zonePoint = new ymaps.GeoObject(zonePointGeometry, zonePointPreset)
      this.searchControl = this.myMap.controls.get('searchControl')
      this.searchControl.options.set(searchControlOptions)
      this.myMap.geoObjects.add(window.zonePoint)
      this.onZonesLoad()
    },
    onZonesLoad() {
      // create deep copy of polygons array, coz Vue add observers (getters and setters)
      // on each coordinates array.
      // destructing make same thing, but longer
      const obj = clone(this.polygons)
      this.mapZones = ymaps.geoQuery(obj).addToMap(this.myMap)
      this.mapZones.each(obj => {
        obj.options.set({
          fillColor: obj.properties.get('fill'),
          fillOpacity: obj.properties.get('fill-opacity'),
          strokeColor: obj.properties.get('stroke'),
          strokeWidth: obj.properties.get('stroke-width'),
          strokeOpacity: obj.properties.get('stroke-opacity')
        })
        obj.events.add('click', () => {
          const zone = obj.properties.get('zone')
          this.updateTableHeaderData('Не указан', zone)
          this.getZoneData(zone)
        })
        // obj.properties.set('balloonContent', obj.properties.get('description'))
      })
      // Проверим попадание результата поиска в одну из зон доставки.
      this.searchControl.events.add('resultshow', e => {
        this.highlightResult(this.searchControl.getResultsArray()[e.get('index')])
      })
    },
    highlightResult(obj) {
      // Сохраняем координаты переданного объекта.
      const coords = obj.geometry.getCoordinates()
      // Находим полигон, в который входят переданные координаты.
      const polygon = this.mapZones.searchContaining(coords).get(0)
      if (polygon) {
        // Уменьшаем прозрачность всех полигонов, кроме того, в который входят
        // переданные координаты.
        this.mapZones.setOptions('fillOpacity', 0.4)
        polygon.options.set('fillOpacity', 0.8)
        // Перемещаем метку с подписью в переданные координаты и
        // перекрашиваем её в цвет полигона.
        window.zonePoint.geometry.setCoordinates(coords)
        window.zonePoint.options.set('iconColor', polygon.properties.get('fill'))
        const name = obj.properties.get('text')
        const zone = polygon.properties.get('zone')
        this.updateTableHeaderData(`${name}, (${coords})`, zone)
        this.getZoneData(zone)
        // Задаем подпись для метки.
        this.setPlaceMarkCaption(obj)
        // console.log(obj.properties.get('text'))
        // console.log(obj.geometry.getCoordinates())
      } else {
        // Если переданные координаты не попадают в полигон, то задаём
        // стандартную прозрачность полигонов.
        this.mapZones.setOptions('fillOpacity', 0.4)
        // Перемещаем метку по переданным координатам.
        window.zonePoint.geometry.setCoordinates(coords)
        // Задаём контент балуна и метки.
        window.zonePoint.properties.set({
          iconCaption: 'Данная местность не попадает ни в один из районов'
        })
        // Перекрашиваем метку в чёрный цвет.
        window.zonePoint.options.set('iconColor', 'black')
      }
    },
    setPlaceMarkCaption(obj) {
      window.zonePoint.properties.set({
        iconCaption: getPlaceMarkCaption(obj)
      })
    },
    updateTableHeaderData(address, feature) {
      this.computedTableHeaderData.userAddress = address
      this.computedTableHeaderData.mapFeature = feature
    },
    getZoneData(id) {
      this.$emit('requestMapResults', {
        id: id,
        tableHeaderData: this.computedTableHeaderData
      })
      if (!this.isShowToggle) {
        this.$emit('showToggle')
      }
      this.$emit('toggleMapHeight', true)
    }
  },
  mounted() {
    ymaps.ready(this.initMap)
  },
  beforeDestroy() {
    delete window.zonePoint
    this.myMap.destroy()
  },
  computed: {
    ...mapState({
      polygons: state => state.mapData.data
    }),
    computedTableHeaderData() {
      return { ...this.tableHeaderData }
    }
  }
}
</script>

<style scoped lang="sass">
.map
  &-wrapper
    @extend %map-wrapper
    &--collapsed
      .map
        height: rem(256)
</style>
